<template>
  <div class="container-fluid px-0">
    <div class="row pt-1">
      <div class="col-lg-12">
        <h3 class="mb-2 text-center">Accident Incident Company Instructions</h3>
      </div>
      <div class="col-lg-5">
        <form class="form-inline small" @submit.prevent="searchAiCompanyInstruction">
          <input class="form-control form-control-sm w-75" type="search"
                 placeholder="Search Instruction Description ..."
                 aria-label="Search Instruction Description ..." v-model="filters.search">
          <button class="e-btn e-btn-outlined-green e-text-hover-white-light-1" type="submit"
                  style="margin-left: -29px; padding-top: 0.35rem; padding-bottom: 0.35rem;">
            <font-awesome-icon class="e-text-green" icon="search"/>
          </button>
        </form>
      </div>
      <div class="col-lg-7 align-items-end">
        <nav aria-label="...">
          <ul class="pagination justify-content-end text-smallest mb-2 pb-0">
            <li class="page-item" :class="{ 'disabled': pagination.currentPage === 1}">
              <button class="page-link" @click="setPage(1)">First</button>
            </li>

            <li v-for="page in paginationPages" :key="page" class="page-item"
                :class="{ 'disabled': page === '...', 'active': page === pagination.currentPage }">
              <button class="page-link" @click="setPage(page)">{{ page }}</button>
            </li>

            <li class="page-item" :class="{ 'disabled': pagination.currentPage === pagination.lastPage}">
              <button class="page-link" @click="setPage(pagination.lastPage)">Last</button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div class="row no-gutters text-center" style="height: 80vh; overflow-y: auto">
      <div class="col-12">
        <table class="small e-tbl">
          <thead class="bg-white" style="position: sticky; top: 0; z-index: 2">
          <tr>
            <th>Target Ship</th>
            <th>Type of Instruction</th>
            <th>Sent Date</th>
            <th>Description</th>
            <th>Status</th>
            <th class="px-3">Progress</th>
            <th>Action</th>
          </tr>

          </thead>
          <tbody>
          <tr v-if="loading">
            <td colspan="100%">
              <loading></loading>
            </td>
          </tr>
          <template v-else>
            <tr v-for="(aicInstruction, index) in allAiCompanyInstructions" :key="index + 1">
              <td class="align-middle pre-wrap-txt" style="width: 120px">
                <span v-if="aicInstruction.related_party_id == null"></span>
                <a type="button"
                   class="nav-link p-0"
                   data-toggle="modal"
                   data-target="#selectedVesselListModal"
                   @click="openPartyVesselList(aicInstruction)"
                   v-else>
                  [ {{ aicInstruction.related_party_code }} ]
                </a>
              </td>
              <td class="align-middle pre-wrap-txt" style="width: 130px">
                {{ aicInstruction.company_instruction_type_name }}
              </td>
              <td class="align-middle pre-wrap-txt" style="width: 100px">
                {{ aicInstruction.sent_at | moment('D MMM YYYY') }}
              </td>
              <td class="align-middle">
                {{ aicInstruction.title }}
              </td>
              <td class="align-middle px-4" width="1" v-if="aicInstruction.canceled_at">
                CANCELED
              </td>
              <td class="align-middle px-4" width="1" v-else>
                {{ aicInstruction.sent_at ? 'SENT' : 'DRAFT' }}
              </td>
              <td class="align-middle" width="1">
                <span v-if="aicInstruction.sent_at" class="font-weight-bold">
                  <span class="e-text-green">{{ aicInstruction.air_ci_completed_vessel }} </span>
                  /
                  {{ aicInstruction.air_ci_total_vessel }}
                </span>
              </td>
              <td class="align-middle" style="width: 130px;">
                <button class="e-btn e-btn-blue e-btn-sm e-text-white my-1"
                        type="button" data-toggle="modal" data-target="#editCompanyInstructionModal"
                        @click="openInstruction(aicInstruction)">
                  <font-awesome-icon class="mr-1 text-primary" icon="eye"/>
                  View
                </button>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
    </div>
    <div class="modal fade" id="editCompanyInstructionModal" tabindex="-1" role="dialog" aria-labelledby="editCompanyInstructionModalTitle" aria-hidden="true" data-backdrop="static">
      <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">Company Instruction</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" style="max-height: 80vh; overflow-y: auto">
            <form @submit.prevent="" class="w-100" v-if="showData" id="UpdateCompanyInstruction">
              <div class="row">
                <div class="col-lg-4">
                  <h5 class="font-weight-bold">Instruction</h5>
                </div>
                <div class="col-lg-8 pl-lg-0" v-if="toEdit">
                  <input type="text" class="form-control form-control-sm mb-2" v-model="companyInstruction.title">
                </div>
                <div class="col-lg-8 pl-lg-0" v-else>
                  <span>
                    <span v-if="companyInstruction.related_party_id == null"></span>
                    <span v-else>[ {{ companyInstruction.related_party_name }} ]</span>
                  </span>
                  {{ companyInstruction.title ? companyInstruction.title : '' }}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4">
                  <h5 class="font-weight-bold">Instruction Detail</h5>
                </div>
                <div class="col-lg-8 pl-lg-0">
                  <div class="pre-wrap-txt textarea-display-card" v-html="companyInstruction.description"></div>
                </div>
              </div>
              <div class="row pt-2">
                <div class="col-lg-4">
                  <h5 class="font-weight-bold">Type of Instruction </h5>
                </div>
                <div class="col-lg-8 pl-lg-0">
                  <select name="" id="" class="form-control form-control-sm w-auto px-4"
                          v-model="companyInstruction.company_instruction_type_id" required disabled>
                    <option :value="null" selected disabled hidden>--- Select Instruction Type ---</option>
                    <option :value="ciType.id" :key="ciType.id"
                            v-for="ciType in airCiTypeKeys">{{ ciType.name }}</option>
                  </select>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4">
                  <h5 class="font-weight-bold">Related Parties</h5>
                </div>
                <div class="col-lg-8 pl-lg-0" v-show="!toEdit">
                  <select class="form-control w-auto form-control-sm my-2 d-inline-block mr-5" readonly disabled
                          v-model="companyInstruction.related_party_id">
                    {{companyInstruction.related_party_id}}
                    <option v-for="relatedParty in relatedParties"
                            :key="relatedParty.id"
                            :value="relatedParty.id">
                      {{relatedParty.party_name}}
                    </option>
                  </select>
                  <button class="e-btn e-btn-yellow text-black-50 font-weight-bold e-btn-md ml-5 px-5"
                          v-if="!companyInstruction.sent_at"
                          type="button" @click="toEdit = true">Edit</button>
                </div>
              </div>
              <div class="row pb-2">
                <div class="col-lg-4">
                  <h5 class="font-weight-bold">Related Vessels</h5>
                </div>
                <div class="col-lg-8 pl-lg-0">
                  <div class="pr-3" v-if="companyInstruction.sent_at">
                    <table class="table border-bottom table-sm">
                      <tbody>
                      <tr v-for="(vesselInstruction, index) in companyInstruction.air_ci_vessels"
                          :key="vesselInstruction.id">
                        <td class="align-middle">
                          {{index+1}}
                        </td>
                        <td class="text-uppercase align-middle">
                          {{vesselInstruction.vessel_name}}
                        </td>
                        <td class="text-uppercase font-weight-bold align-middle"
                            :class="{
                      'e-text-red': vesselInstruction.air_ci_status_id == null || vesselInstruction.air_ci_status_id==1,
                      'e-text-yellow': vesselInstruction.air_ci_status_id == null || vesselInstruction.air_ci_status_id==2,
                      'e-text-green': vesselInstruction.air_ci_status_id == null || vesselInstruction.air_ci_status_id==3,
                      'text-dark': vesselInstruction.air_ci_status_id == null || vesselInstruction.air_ci_status_id==4,
                      }"
                        >
                          {{vesselInstruction.air_ci_status}}
                        </td>
                        <td>
                          <button
                            v-if="[2,3].includes(vesselInstruction.air_ci_status_id)|| (vesselInstruction.air_ci_status_id==4 && vesselInstruction.attachmentCount > 0)"
                            class="e-btn e-btn-blue e-btn-sm e-text-white"
                            id="viewVesselResponseBtn"
                            data-toggle="modal"
                            data-target="#viewMasterComplianceInstruction"
                            @click="viewVesselResponse(vesselInstruction)"
                            type="button">
                            <font-awesome-icon icon="eye" class="mr-1" />View
                          </button>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="row" style="height: 350px; overflow:auto;" v-else>
                    <div class="col-lg-6" v-for="vessel in vesselKeys" :key="vessel.id">
                      <div class="form-check">
                        <input type="checkbox"
                               class="form-check-input mt-0"
                               :id="vessel.id"
                               :value="vessel.id"
                               :disabled="!toEdit"
                               :checked="companyInstruction.related_vessel_ids"
                               v-model="companyInstruction.related_vessel_ids"
                        >
                        <label class="form-check-label" :for="vessel.id">{{ vessel.name }}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal fade" id="viewMasterComplianceInstruction"
                     tabindex="-1" role="dialog"
                     aria-labelledby="viewMasterComplianceInstruction"
                     aria-hidden="true"
                     data-backdrop="static"
                >
                  <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title mr-5" id="exampleModalLongTitle">REPORT NO. {{ companyInstruction.report_code }}  </h5>
                        <div class="btn-group ml-5" role="group" aria-label="Basic example">
                          <button type="button" class="bg-white font-weight-bold border-0 pr-3" disabled>Status: </button>
                          <button type="button" class="e-btn px-3 e-btn-outlined-blue"
                                  disabled
                                  v-if="vesselInstruction.air_ci_status_id == 4">
                            CANCELED
                          </button>
                          <button type="button" class="e-btn px-3 e-btn-blue"
                                  disabled
                                  v-else>
                            ONGOING
                          </button>
                        </div>
                        <button type="button" class="close" @click="closeSpecificModal('viewMasterComplianceInstruction')">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <div class="row mb-2 text-left">
                          <div class="col-lg-2 font-weight-bold">Job Completion:</div>
                          <div class="col-lg-10">
                                <span v-if="vesselInstruction.canceled_at || vesselInstruction.air_ci_status_id ==4">
                                  <select class="form-control w-auto form-control-sm d-inline-block  mr-5" disabled>
                                    <option :value="4">CANCELED</option>
                                  </select>
                                </span>
                            <span v-else>
                                  <select class="form-control w-auto form-control-sm d-inline-block  mr-5" disabled
                                          v-model="vesselInstruction.air_ci_status_id">
                                    <option :value="1">Pending</option>
                                    <option :value="2">In Progress</option>
                                    <option :value="3">Done</option>
                                  </select>
                                </span>
                          </div>
                        </div>
                        <div class="row mb-2 text-left">
                          <div class="col-lg-2 font-weight-bold">
                            Job Done:
                          </div>
                          <div class="col-lg-10">
                            <div class="pre-wrap-txt textarea-display-card"
                                 v-html="vesselInstruction.response_details || '' "/>
                          </div>
                        </div>
                        <div class="row mb-2 text-left" style="height: 450px; overflow-y: auto">
                          <div class="col-lg-2 font-weight-bold">
                            Attachments
                          </div>
                          <div class="col-lg-10">
                            <div class="row no-gutters">
                              <div v-for="file in instructionFiles" class="col-lg-2 col-sm-6 m-1">
                                <div id="attachment-card" class="card p-1" style="min-height: 50px"
                                     :title="file.attachment_name">
                                  <div class="dropdown dropright">
                                    <div
                                      data-toggle="dropdown"
                                      id="attachmentDropdown"
                                      class="text-center"
                                      aria-haspopup="true" style="cursor:pointer"
                                    >
                                      <div class="card-img-top text-center">
                                        <img :src="file.thumbnail" alt="Attachment Thumbnail" style="width: 50px"/>
                                      </div>
                                      <small id="attachment-name">{{ file.attachment_name }}</small>
                                      <div aria-labelledby="attachmentDropdown" class="dropdown-menu">
                                        <button class="dropdown-item text-secondary font-weight-bolder"
                                                type="button"
                                                v-if="file.extension === 'pdf'"
                                                @click="viewFile(file.id)"
                                        >
                                          <font-awesome-icon class="ml-2 mr-1 text-primary" icon="eye"/>
                                          View Attachment
                                        </button>
                                        <button class="dropdown-item text-secondary font-weight-bolder"
                                                v-if="!(vesselInstruction.canceled_at || vesselInstruction.air_ci_status_id ==4)"
                                                @click="downloadFile(file.id)"
                                                type="button"
                                        >
                                          <font-awesome-icon class="ml-2 mr-1 text-primary" icon="download"/>
                                          Download Attachment
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <div class="modal fade"
         id="selectedVesselListModal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="selectedVesselListModalTitle"
         aria-hidden="true"
         data-backdrop="static">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="mb-0"> {{ relatedPartyDetails.related_party_name }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <h4 class="mb-0 text-center e-text-orange" v-if="!relatedPartyDetails.air_ci_vessels || !relatedPartyDetails.air_ci_vessels.length ">
              Instruction NOT submitted to vessels
            </h4>
            <div style="max-height: 450px; overflow-y: auto">
              <p class="mb-1" v-for="selectedVessels in relatedPartyDetails.air_ci_vessels" :key="selectedVessels.id">
                <font-awesome-icon icon="ship"  style="width: 25px" />
                {{selectedVessels.vessel_name}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppLoading from "@/components/elements/AppLoading.vue";
import {PaginationMixin} from "@/mixins/PaginationMixin";
import {mapActions, mapGetters} from "vuex";
import {ReportService} from "@/services/ReportService";
import {FileService} from "@/services/FileService";

export default {
  name: "AirCompanyInstructionList",
  data() {
    return {
      loading: false,
      filters: {
        page: 1,
        search: ''
      },
      toEdit: false,
      showData: false,
      companyInstruction: {},
      vesselInstruction:{},
      relatedPartyDetails: {},
      instructionFiles: [],
    }
  },
  components: {
    'loading': AppLoading
  },
  mixins: [PaginationMixin],
  methods: {
    ...mapActions(['getAllAiCompanyInstructions','getAirCiTypeKeys','getRelatedParties']),
    setPage(page) {
      this.pagination.currentPage = page
      this.filters.page = page
      this.getAllAiCompanyInstructions(this.filters)
    },
    openInstruction(instruction) {
      this.companyInstruction = Object.assign(instruction);
      this.showData = true
      this.toEdit = false
    },
    async viewVesselResponse(vesselInstruction){
      this.vesselInstruction=Object.assign({},vesselInstruction)
      this.instructionFiles = await ReportService.getCiVesselFiles(vesselInstruction.id)
    },
    async downloadFile(attachmentId){
      swal.fire({
        title:`Downloading file... <br> Please Wait`
      })
      swal.showLoading();
      let response=await ReportService.viewAirCiVesselFile(attachmentId);
      if(response?.file !== null){
        swal.close();
        let blob=FileService.base64FileToBlob(response.file,response.mimeType);
        const link = document.createElement('a');
        link.setAttribute('href', URL.createObjectURL(blob));
        link.setAttribute('download', response.fileName.substr(0,response.fileName.lastIndexOf('.')));
        document.body.appendChild(link);
        link.click();
      }
    },
    closeSpecificModal(modalId) {
      $('#'+modalId).modal('hide')
    },
    openPartyVesselList(details){
      this.relatedPartyDetails = Object.assign(details);
    },
    async viewFile(attachmentId){
      swal.fire({
        title:`Viewing file... <br> Please Wait`
      })
      swal.showLoading();
      let response=await ReportService.viewAirCiVesselFile(attachmentId);
      if(response?.file !== null){
        swal.close();
        let blob=FileService.base64FileToBlob(response.file,response.mimeType);
        const link = document.createElement('a');
        link.setAttribute('href', URL.createObjectURL(blob));
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
      }
    },
    async searchAiCompanyInstruction(){
      this.loading = true
      this.filters.page = 1
      await this.getAllAiCompanyInstructions(this.filters)
      this.loading = false
    }
  },
  computed: {
    ...mapGetters(['allAiCompanyInstructions','airCiTypeKeys','relatedParties'])
  },
  async mounted() {
    this.loading = true
    await this.setPage()
    await this.getAirCiTypeKeys()
    await this.getRelatedParties()
    this.loading = false
  }
}
</script>

<style scoped>
::v-deep .pre-wrap-txt.textarea-display-card p {
  margin-bottom: 0 !important;
}
#attachment-card:hover > .dropdown small {
  color: white;
}
#attachment-card:hover {
  background: rgba(255, 0, 0, 0.7);
  transition: 0.5s;
}
#attachment-name{
  width: 100%;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
